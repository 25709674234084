/*.MapMap {
    display: flex;
    flex-flow: column;
    height: 100%;
}
*/

@media (max-width: 576px) {
    .hideOnSmall {
        display: none;
    }
}

.mapContainer {
    height: 100%;
    /*
    position: relative;
    overflow: hidden;

     */
}

.olmap {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    margin-bottom: 100%;
    opacity: var(--frilufts-map-tilelayer-opacity);
}

.ol-attribution {
    display: none;
}

ion-backdrop {
    opacity: 1;
    /* background: var(--ion-color-primary); */
    background: white;
}

@media (prefers-color-scheme: dark) {
    .olmap {
        opacity: 1;
    }
    ion-backdrop {
        opacity: 1;
        /* background: var(--ion-color-primary); */
        background: white;
    }
}

.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--ion-background-color, #fff); */
    width: 160px;
    height: 100px;
    border-radius: 10px;
}

/**
TODO: Fix width for different devices.
 */
.mapToaster {
    top: calc(var(--toender-header-height) + 35px);
    --background: #f4f4fa;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
}

.mapToaster::part(message) {
    font-style: italic;
    width: 90%;
}

.mapToaster::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    /*width: 50%;*/
    font-size: 12px;
}

#xxx {
    height: 44px;
    background-color: transparent;
    bottom:50px;
    position: absolute;
    width: 100%;
}

ion-fab-button::part(native) {
    --background: white;
}

ion-fab-button.zero::part(native) {
    --background: lightgrey;
}

ion-fab-button.one::part(native) {
    --background: #bababa;
}

/* TRACKING */
ion-fab-button.two::part(native) {
    --background: #1820ff;
    --color: white;
    opacity: 0.9;
}

ion-fab-button.two::part(native) > .ionicon {
    opacity: 1;
}

ion-fab-button.three::part(native) {
    --background: #ffffff;
}

ion-fab-button.four::part(native) {
    --background: white;
}

.ol-icon,
.markerShadow {
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 1); */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
}

ion-popover {
    --backdrop-opacity: 0.6;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
    --color: white;
    --width: 300px;
    top: -7px;
    left: 10px
}

ion-popover ion-content {
    /* --background: rgba(40, 173, 218, 0.6); */
}

ion-popover::part(backdrop) {
    background-color: rgb(0, 0, 0);
}
