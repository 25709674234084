.mapContainerOne, .background {
    background-color: rgba(255, 255, 255, 0.4);
    width: 215px;
    height: 466px;
    border-radius: var(--toender-facility-map-border-radius);
}

.mapContainer {
    border-radius: var(--toender-facility-map-border-radius);
    height:466px;
}

.mapContainer .ol-viewport {
    border-radius: var(--toender-facility-map-border-radius);
}

.detailMapBackground::-webkit-scrollbar {
    display: none !important;
}

.mapContainer::-webkit-scrollbar {
    display: none;
}

.mapContainerOne::-webkit-scrollbar {
    display: none;
}

.background::-webkit-scrollbar {
    display: none;
}

.moreButton {
    height: 10px;
    padding-bottom: 30px;
    font-size: 12px;
    margin-top:0;
}

.mapContainerOne {
    position: relative;
    display: inline-block;
}

.mapContainerOne::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 215px;
    height: 466px;
}
