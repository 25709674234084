.container {
    position: relative;
    width: 100%;
    height: 34px;
    margin-top: 6px;
}

.container:hover {
    color: white;
}

.filterBadge {
    position: absolute;
    top: 0;
    right: 40%;
    background-color: red;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    z-index:999;
    line-height: 20px;
}

ion-icon {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 30px;
}

.filterIcon {
    font-size: 22px;
}
