ion-page {
    padding: 10px;
}

.rotate {
    transform: rotate(-45deg);
}

pre {
    font-size: 10px;
}
