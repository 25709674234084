.container {
    position: relative;
    padding: 10px;
    height: 60px;
    margin-bottom: 10px;
}

.top {
    position: absolute;
    top: 12px;
    right: -16px;
    background-color: transparent;
    border-radius: 90px;
    height: 35px;
    width: 35px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
}

.bottom {
    border-radius: 10px; /* @bes */
    position: absolute;
    top: 0;
    left: 0;
    background-color: #d9d9d9; /* var(--toender-facility-item-background-color);*/
    height: 60px;
    margin-right: 10px;
    width: var(--slide-width);
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
}

.Bc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
}

.FriluftsIcon:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

.facilityType {
    font-size: 12px;
}

.bottom:hover {
    /* background-color: var(--toender-facility-item-background-color);*/
    filter: brightness(100%);
}
