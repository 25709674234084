.accordionThemeItem {
    border-radius: 13.5px;
    margin-right: 24px!important;
    margin-left: 24px!important;
}

.accordionThemeItem.active {
    background-color: var(--toender-darkgray);
}

.accordionThemeItem.inactive {
    background-color: var(--toender-lightgray);
}

ion-accordion .accordionThemeItem label {
    margin-left: 0;
}

ion-accordion .padding {
    height: 29px;
    padding-bottom: 30px;
    padding-top: 3px;
    padding-left: 15px;
    margin-bottom: 6px;
    margin-left: 15px;
    margin-right: 15px;
}

ion-accordion ion-icon {
    float: right;
    margin-left: 8px;
}

ion-item::part(native) {
    background: white;
    color: black;
    border-bottom-color: #fff;
    border-right-color: white;
    border-left-color: white;
    border-width: 1px;
    height: 60px;
}

/*
ion-item::part(native)::part(icon-inner) {
    background-color: red;
}
 */

ion-accordion ion-item::part(detail-icon) {
    color: white;
    opacity: 1;
    font-size: 20px;
}

.accordion-toggle-icon {
    font-size: 90px;
    color: #333;
}

/* Set the color of the toggle icon to blue
ion-accordion ion-item[slot="header"] ion-icon {
    color: blue;
}
 */

/* Set the margin of the toggle icon to 10px */
ion-accordion ion-item[slot="header"] ion-icon {
    margin: 0 5px;
}

/*
ion-accordion ion-item[slot="header"] ion-icon .icon-inner svg {
    fill: red; /* Change the fill color
}
*/

