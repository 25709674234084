.badge {
    background-color: lightgray;
    border-radius: 25px;
    display: flex;
    align-items: center;
    width: fit-content;
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
}

.badge:hover {
    filter: brightness(0.9);
}

.label {
    font-size: 14px;
    font-weight: normal!important;
    padding: 5px 15px;
    display: flex; /* Make the icon container a flex container too */
    align-items: center; /* Center the icon vertically */
}

.icon {
    padding: 5px;
    display: flex; /* Make the icon container a flex container too */
    align-items: center; /* Center the icon vertically */
}


.downloadIcon {
    color: black;
    padding-left: 8px;
}

.badge > div > ion-icon {
    font-size: 25px;
    margin: 0!important;
}
